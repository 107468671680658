import { Statuses } from "@/api/scoring/types";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
} from "@/components/ui/dropdown-menu";
import { Separator } from "@/components/ui/separator";
import { config } from "@/configs/address-statuses";
import { COLORS } from "@/constants/colors-rating";
import type { Score } from "@/types/addresses";
import { DropdownMenuTrigger } from "@radix-ui/react-dropdown-menu";
import { useNavigate } from "@tanstack/react-router";
import type { ColumnDef } from "@tanstack/react-table";
import { ArrowUpDown, ChevronDown, Files, MoveUpRight } from "lucide-react";
import { CopyToClipboard } from "react-copy-to-clipboard";

export const columns: ColumnDef<Score>[] = [
  {
    accessorKey: "address",
    header: ({ column }) => {
      return (
        <Button
          className="-ml-4"
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Address
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
  },
  {
    accessorKey: "score",
    header: ({ column }) => {
      return (
        <Button
          className="-ml-4"
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Score
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: (info) => (
      <p className="font-bold text-base">
        {info.renderValue() ? String(info.renderValue()) : "-"}
      </p>
    ),
  },
  {
    accessorKey: "energyRating",
    header: ({ column }) => {
      return (
        <Button
          className="-ml-4"
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Rating
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: (info) => {
      const rating: string = info.getValue() as string;

      return (
        <p
          className={`${COLORS[rating]} pl-2 h-6 w-10 rounded-md font-bold text-base`}
        >
          {info.renderValue() ? String(info.renderValue()) : "-"}
        </p>
      );
    },
  },
  {
    accessorKey: "postcode",
    header: "Post code",
  },
  {
    accessorKey: "status",
    header: ({ column }) => {
      return (
        <Button
          className="-ml-4"
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Status
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: (info) => {
      return (
        <Badge variant={config[info.getValue() as Statuses]}>
          {String(info.renderValue())}
        </Badge>
      );
    },
  },
  {
    id: "detailedScore",
    cell: ({ row }) => {
      const navigate = useNavigate();

      return (
        <div className="flex justify-end">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                className="mr-2.5"
                variant="outline"
                size="sm"
                disabled={row.original.status !== Statuses.CALCULATED}
              >
                <Files className="h-4 w-4 mr-2" />
                Copy
                <Separator orientation="vertical" className="ml-4 h-[50%]" />
                <ChevronDown className="ml-4 h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <CopyToClipboard text={row.original.id}>
                <DropdownMenuItem>Scoring ID</DropdownMenuItem>
              </CopyToClipboard>
              <CopyToClipboard text={row.original.apiPlusLink}>
                <DropdownMenuItem>Link to API+</DropdownMenuItem>
              </CopyToClipboard>
            </DropdownMenuContent>
          </DropdownMenu>
          <Button
            variant="outline"
            size="sm"
            className="bg-activeNav-color text-white"
            onClick={() =>
              navigate({
                to: "/detailed-score/$id",
                params: { id: row.original.id },
              })
            }
            disabled={row.original.status !== Statuses.CALCULATED}
          >
            <MoveUpRight className="h-4 w-4" />
          </Button>
        </div>
      );
    },
  },
];
